import http from "./Middleware";

class Request {
  constructor(resource) {
    this.resource = resource;
  }

  async get(id) {
    try {
      const res = await http.get(`${this.resource}/${id}`);
      return res.data;
    } catch (error) {
      throw error.data;
    }
  }

  async getquery(id) {
    try {
      const res = await http.get(`${this.resource}/?coupon=${id}`);
      return res.data;
    } catch (error) {
      throw error.data;
    }
  }

  async getAll(params = {}) {
    try {
      const res = await http.get(this.resource, { params });
      return res.data || [];
    } catch (error) {
      throw error.data;
    }
  }

  async create(body) {
    try {
      const res = await http.post(this.resource, body);
      return res;
    } catch (error) {
      throw error.data;
    }
  }

  async update(body) {
    try {
      const res = await http.put(this.resource, body);
      return res.data;
    } catch (error) {
      throw error.data;
    }
  }

  async delete(id) {
    try {
      const res = await http.delete(`${this.resource}/${id}`);
      return res.data;
    } catch (error) {
      throw error.data;
    }
  }
}

export default Request;
