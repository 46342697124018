import React, { useEffect, useReducer } from "react"

// Context
export const AuthStateContext = React.createContext()

// Reducers
import { AuthReducer } from './reducer'

// Services
import { getUserInfo } from '../../../services/Auth'


export const AuthProvider = ({ children }) => {
  let user = null
  try {
    user = JSON.parse(window.localStorage.getItem('@USER'))
  } catch (error) {
    // Handle Error
  }
  const initialState = {
    user: user || { modules:[] },
    loading: false,
    errorMessage: null,
    successMessage: null
  }

  const [authState, dispatch] = useReducer(AuthReducer, initialState)

  useEffect(() => {
    (async function() {
      try {
        const token = window.localStorage.getItem('@TOKEN')
        if (token !== null) {
          const res = await getUserInfo()
          if (res.status === 200) {
            dispatch({ type:'USER_INFO', payload: { user: res.result } })
          }
        }
      } catch (error) {
        //
      }
    })()
  }, [])

  return (
    <AuthStateContext.Provider value={{ ...authState, dispatch }}>
      {children}
    </AuthStateContext.Provider>
  )
}
