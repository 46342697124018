// export const API_URL = 'https://develop.aloharmony.com'
// export const API_URL = "https://api.aloha.crizz.io";
export const API_URL = "https://apirest.aloharmony.com";

export const ENDPOINTS = {
  LOGIN: "/admin/login",
  DASHBOARD: {
    DASHBOARD: "/dashboard",
    TECHNIQUES: "dashboard/techniques",
    CHART: "dashboard/membership-chart",
    CLIENT: "dashboard/clients",
    SESSION: "dashboard/sessions",
    MEMBERSHIPS: "dashboard/memberships",
  },
  ADMINS: "/admin",
  // USER_INFO: "user/info",
  USER_INFO: "admin/info",
  USER: "/user",
  USER_REFERRALS: "/user/referralUsers",
  USER_TRACKS: "/history/user",
  BANNERS: "/banner-explorer",
  LANGUAGES: "/settings/languages",
  CATEGORIES: "/category",
  BENEFIT: "/benefit",
  SUBCATEGORY: "/subCategory",
  SUBCATEGORY_ADMIN: "/view/subCategory",
  ALL_BENEFITS: "/admin/subCategory",
  TAGS: "/tag",
  TRACKS: "/track",
  TRACKSBYUSER: "/track/user-history",
  RANKING: "/track/ranking",
  TRACKS_ARTIST: "/track/artists",
  TRACKS_ADMIN: "/track/admin",
  TRACKS_STATUS: "/track",
  TRACKS_TYPES: "/type",
  TRACKS_TECHNIQUE: "/settings/techniques",
  PLAYLIST: "/playlist",
  PLAYLIST_ADMIN: "/playlist",
  PLANS: "/plan",
  PLANS_ADMIN: "/plan",
  LEARN: "/learn",
  COUPON: "/coupon",
  COUPON_BY_USER:"user/users-by-coupon",
  NOTIFICATIONS: "/notification",
  NOTIFICATIONS_EXPRESS: "user/express-notifications",
  PARTNERS: "/partner",
  PARTNERS_REFERRALS: "/partner/referrals/admin",
  SETTINGS: {
    GET_ALL: "/settings",
    FREEMIUM: "/updateFreemium",
    LABEL: "/settings",
    PARTNER: "/updatePartner",
    REFERRALS: "/updateReferrals",
    TAGS: "/updateTags",
    COMMISSIONS: "/updateCommissions",
    B2BCOST: "/updateB2bCost",
    MEMBERSHIPCOST: "/membershipCost",
  },
  BONUS: "/bonus",
  CLIENT: "/partner",
  CLIENTLEVEL: "/partner/referrals",
  REFERRALS: "/partner/admin/directReferrals",
  PROFITS: "/partner/admin/profits",
  PROFITSDETAIL: "/B2B/profits/detail",
  INITIALBANNERS: "/banner-explorer",
  INITIALBANNERSINAPP: "/banner-inapp",
  INITIALBANNERSLOGIN: "/banner-login",
  INDEX: {
    BANNERSEXPLORE: "banner-explorer/index",
    BANNERSINAPP: "banner-inapp/index",
    NOTIFICATIONS: "notification/index",
    PLAYLIST: "playlist/index",
    PLAN: "plan/index",
  },
};
