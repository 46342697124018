// ** Logo
import logo from '@src/assets/images/logo/IconAloha.png'
import './styles/Fallback-spinner.scss'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100 Spinner-container'>
      <img className='fallback-logo revi' src={logo} alt='logo' />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
