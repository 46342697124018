import axios from 'axios'
import { API_URL } from '../constants'
const http = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json'
  }
})

http.interceptors.response.use((response) => {
  return response
}, function (error) {
  // Do something with response error
  // if (error.response.status === 401 || error.response.status === 500 || error.response.status === 403) {
  //   if (error.response.status === 403) {
  //     (() => (window.location.href = '/login'))()
  //   }
  // }
  if (error.response.status === 403) {
    localStorage.removeItem('@TOKEN');
    (() => (window.location.href = '/login'))()
  }
  return Promise.reject(error.response)
})

http.interceptors.request.use(
  config => {
    let token = null
    try {
      token = localStorage.getItem('@TOKEN')
    } catch (error) {
      //hanlde error
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    Promise.reject(error)
  })

export default http
